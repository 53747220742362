import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import authProvider from './core/authProvider';
import { backendUrl } from './core/consts';
import dataProvider from './core/dataProvider';
import { httpClient } from './core/httpClient';
import { CustomLayout } from './layout/Layout';
import { ordersResourceProps } from './pages/orders';
import { payoutsResourceProps } from './pages/payouts';
import { adminResourceProps } from './pages/admins';
import { retailerResourceProps } from './pages/retailers';
import { supplierResourceProps } from './pages/suppliers';
import { PayoutSupplierOrderList } from './pages/payouts/suppliers/PayoutSupplierOrderList';
import { PayoutOrderList } from './pages/payouts/PayoutOrderList';
import { payoutsSuppliersResourceProps } from './pages/payouts/suppliers';
import { announcementsResourceProps } from './pages/announcements';

const App = () => {
  const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });
  return (
    <Admin
      i18nProvider={i18nProvider}
      dataProvider={dataProvider(backendUrl, httpClient)}
      authProvider={authProvider}
      layout={CustomLayout}
      disableTelemetry
    >
      <Resource name="retailers" {...retailerResourceProps} />
      <Resource name="suppliers" {...supplierResourceProps} />
      <Resource name="admins" {...adminResourceProps} />
      <Resource name="plans" />
      <Resource name="retailer-names" />
      <Resource name="payouts/suppliers/orders" list={PayoutSupplierOrderList} />
      <Resource name="payouts/suppliers" {...payoutsSuppliersResourceProps} />
      <Resource name="payouts/orders" list={PayoutOrderList} />
      <Resource name="payouts" {...payoutsResourceProps} />
      <Resource name="orders" {...ordersResourceProps} />
      <Resource name="announcements" {...announcementsResourceProps} />
    </Admin>
  );
};

export default App;
