import { FC } from 'react';
import { Datagrid, DateField, DeleteButton, EditButton, List, ListProps, TextField } from 'react-admin';

export const AnnouncementList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} sort={{ field: 'createdAt', order: 'desc' }} {...props}>
    <Datagrid>
      <TextField label="Heading" source="title" />
      <TextField label="Link URL" source="linkURL" />
      <TextField label="Link text" source="linkText" />
      <DateField source="createdAt" showTime />
      <DeleteButton mutationMode="optimistic" />
      <EditButton />
    </Datagrid>
  </List>
);
