import { required, SimpleForm, TextInput, SelectInput, SimpleFormProps } from 'react-admin';

const APP_TYPE = {
  ALL: 'All',
  RETAILER: 'Retailer',
  supplier: 'Supplier',
};

const ANNOUNCEMENT_TYPE = {
  NEW_SUPPLIER: 'New Supplier',
  NEW_FEATURE: 'New Feature',
  NEWS_AND_UPDATES: 'News & Updates',
  DROPSHIP: 'Dropship 101',
};

export const AnnouncementForm = (props: Omit<SimpleFormProps, 'children'>) => (
  <SimpleForm {...props}>
    <TextInput source="title" validate={required()} />
    <SelectInput
      source="appType"
      choices={Object.values(APP_TYPE).map((aP) => ({ id: aP, name: aP }))}
      validate={required()}
    />
    <SelectInput
      source="announcementType"
      choices={Object.values(ANNOUNCEMENT_TYPE).map((aP) => ({
        id: aP,
        name: aP,
      }))}
      validate={required()}
    />
    <TextInput source="description" label="Description" multiline minRows={8} />
    <TextInput source="linkText" />
    <TextInput source="linkURL" label="Link url" />
  </SimpleForm>
);
