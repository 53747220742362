import { FC } from 'react';
import { Create, EditProps } from 'react-admin';
import { AnnouncementForm } from './AnnouncementForm';

export const AnnouncementCreate: FC<EditProps> = (props) => {
  return (
    <Create mutationMode="pessimistic" {...props}>
      <AnnouncementForm redirect="list" />
    </Create>
  );
};
