import { AssignmentTurnedIn } from '@material-ui/icons';
import { ResourceProps } from 'react-admin';
import { AnnouncementList } from './AnnouncementList';
import { AnnouncementCreate } from './AnnouncementCreate';
import { AnnouncementEdit } from './AnnouncementEdit';

const options = {
  label: 'Announcements',
};

export const announcementsResourceProps: Omit<ResourceProps, 'name'> = {
  list: AnnouncementList,
  create: AnnouncementCreate,
  edit: AnnouncementEdit,
  icon: AssignmentTurnedIn,
  options,
};
